.footer {
    position:relative;
    bottom:0;
    width:100%;
    background-color:#98BAEA;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 5;
    margin-top: 100px;
    color:white;
    font-family: 'Fjalla One', sans-serif;
}

@media (max-width: 767px) {
    .footer {
        height: 50px;
        font-size:20px;
    }
}

@media (min-width: 768px) {
    .footer {
        height: 50px;
        font-size:20px;
    }
}

@media (min-width: 1024px) {
    .footer {
        height: 80px;
        font-size:35px;
    }
}
