.header_hat {
    top: 0;
    background: url(../images/background.jpg) no-repeat;
    margin-top: -80px;
    background-size: cover;
    display: flex;
    align-items: center;
}

.container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 90px;
}

.demo_item {
    color: white;
    font-size: 60px;
    text-align: center;
    width:100%;
    /*margin: auto 0;*/
}

.slick-dots li button:before{
    color: #858585;
    opacity: 0.5;
    font-size: 14px;
}
.slick-dots li.slick-active button:before {
    color: #363636;
    opacity: 0.8;
    font-size: 14px;
}

.header_hat .slick-next {
    right: 5px;
}

.header_hat .slick-prev {
    left: 5px;
}

.header_hat  .slick-initialized.slick-slider {
    display: flex;
    align-items: center;
}

@media (max-width: 476px) {
    .demo_item {
        font-size: 50px;
        font-weight: 400;
    }

    .slick-dots li{
        margin: 0;
    }

    .slick-dots li button:before{
        font-size: 8px;
    }
    .slick-dots li.slick-active button:before {
        font-size: 8px;
    }
}
