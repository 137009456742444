.price_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:0 50px 100px;
    height:100%;
    position: relative;
}

.price_container_title {
    font-size: 60px;
    color:#47505E;
    text-align: center;
    margin-top:0;
    margin-bottom:10px;
    font-weight: 300;
}

.price_item{
    min-height: 625px;
    background-color: rgba(152, 186, 234, 0.2);
    padding: 30px 0;
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto;
}

.price_image{
    padding-top: 5px;
    border-radius: 50%;
    margin: 0 auto;
}

.price_line{
    width:80%;
    margin-top: 24px;
    margin-bottom: 34px;
    color: rgba(71, 80, 94, 0.5);
}

.price_title_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.price_title {
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    color: #47505E;
    padding-bottom: 10px;
    text-align: center;
}

.gift_certificate_title {
    margin:0;
    font-size: 15px;
    color: #47505E;
}

.action_duration{
    margin-top: 15px;
    font-size: 20px;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-self: center;
    font-weight: 400;
    color: #47505E;
}

.price {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 40px;
    font-family: 'Fjalla One', sans-serif;
}

.price_value {
    font-size: 100px;
    font-weight: 500;
    color: #47505E;
}

.price_currency {
    font-size: 15px;
    color: #47505E;
}

.price_container .slick-next:before  {
    content: ""
}

.price_container .slick-prev:before {
    content: ""
}

.price_info_container{
    width:100%;
    position: absolute;
    bottom:10px;
    left:50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
}


@media (max-width: 767px) {
    .price_item{
        width: 80% !important;
    }
}

@media (min-width: 768px) {
    .price_item{
        width: 80% !important;
    }
}

@media (min-width: 1024px) {
    .price_item{
        width: 96% !important;
    }
}

@media (max-width: 476px) {
    .price_container{
        margin:0 20px 50px;
    }

    .price_container_title{
        margin-top: 30px;
        font-size: 40px;
    }

    .price {
        padding-top: 20px;
    }

    .price_value {
        font-size: 70px;
    }

    .price_currency {
        font-size: 15px;
    }

    .price_item {
        min-height: 560px;
        width: 97% !important;
    }

    .price_title_container{
        margin-bottom: 18px;
    }

    .price_image{
        padding-bottom: 10px;
    }
}
