.header {
    position:absolute;
    top:0;
    width:100%;
    height: auto;
    padding: 15px 0;
    background-color:rgba(71, 80, 94, 0.5);
    background-attachment: fixed;
    z-index: 5;
    font-family: 'Fjalla One', sans-serif;
}

.logo{
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 37px;
    color: #ffffff;
    letter-spacing: -2px;
}

.svg {
    width: 60px;
    height: 90%;
}

.phone{
    font-size: 26px;
    margin-left: 30px;
    color:white;
}

.phone:hover{
    color:#98BAEA;
}


.contacts_container {
    display: flex;
    align-items: center;
}

.media_container {
    display: flex;
    align-items: center;
}

.contact {
    width: 45px;
    height: 45px;
    margin-left: 20px;
}

.contact:hover{
    opacity:1;
}

@media (min-width: 768px) {
    .header {
        display: flex;
        justify-content: space-between;
    }

    .contacts_container {
        margin-right: 0;
        flex-direction: row;
    }

    .logo {
        font-size: 37px;
    }

    .phone_container {
        margin-right: 20px;
    }
}

@media (min-width: 1024px) {
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .contacts_container {
        margin-right: 50px;
    }

    .logo {
        margin-left: 50px;
        margin-bottom: 0;
    }

    .phone_container {
        margin-right: 20px;
    }
}

@media (max-width: 767px) {
    .header {
        height: 35px;
        display: flex;
        justify-content: space-around;
    }

    .contacts_container {
        margin-right:5px;
    }

    .logo {
        font-size: 27px;
        margin-left: 5px;
    }

    .phone_container {
        margin-right: 0;
    }

    .phone_container a {
        font-size: 17px;
    }

    .contact{
        margin-left: 15px;
    }

    .media_container img{
        width: 30px;
    }
}

@media (max-width: 476px) {
    .header{
        height: 40px;
        padding: 0;
    }
    .logo {
        font-size: 20px;
        margin-left: 5px;
    }

    .svg {
        width: 35px;
        height: 70%;
    }

    .contacts_container {
        margin-right:5px;
    }

    .phone_container a {
        font-size: 15px;
    }

    .contact{
        margin-left: 10px;
    }

    .media_container img{
        width: 25px;
        height: 25px;
    }
}
