.info_container{
    margin:0 50px;
}

.info_title{
    color: #47505E;
    font-size: 26px;
    font-weight: 300;
    text-align: center;
    max-width: 350px;
    line-height: 32px;
}

.info_element{
    width:450px;
    min-height:460px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info_svg{
    width: 130px;
}

@media (max-width: 767px) {
    .info_container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .info_element{
        min-height:200px;
    }

    .info_svg{
        width: 100px;
        height: 150px;
    }
}

@media (min-width: 768px) {
    .info_container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1024px) {
    .info_container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
