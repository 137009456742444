.icon-call {
    position: absolute;
    z-index: 10;
    top: 30%;
    left: 15%;
    animation: pulse-dot 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

.phone-btn {
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.image_container .phone {
    margin: 0;
    padding: 30px;
}

.pulsating-circle {
    z-index: 9;
    position: fixed;
    right: 20px;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 100%;
}

.image_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 15%;
    display: flex;
    justify-content: center;
    border-radius: 100%;
}

.pulsating-circle:before {
    content: '';
    position: relative;
    display: block;
    box-sizing: border-box;
    height: 160%;
    margin-left: -30%;
    margin-top: -30%;
    width: 160%;
    border-radius: 100%;
    background-color: #f8b528;
    animation: pulse-ring 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f8b528;
    border-radius: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    animation: pulse-dot 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}


@keyframes pulse-ring {
    0% {
        transform: scale(.33);
    }
    80%, 100% {
        opacity: 0;
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.8);
    }
}

.hidden {
    display: none;
}

@media (max-width: 767px) {
    .pulsating-circle {
        width: 60px;
        height: 60px;
        bottom: 1%;
        right: 1%;
    }

    .icon-call {
        width: 25px;
    }
}

@media (min-width: 768px) {
    .pulsating-circle {
        width: 60px;
        height: 60px;
        bottom: 1%;
    }

    .icon-call {
        width: 25px;
    }
}

@media (min-width: 1024px) {
    .pulsating-circle {
        width: 120px;
        height: 120px;
        bottom: 5%;
    }

    .icon-call {
        width: 50px;
    }
}
