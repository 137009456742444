.map_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
}

.map_title {
    font-size: 60px;
    color:#47505E;
    margin-top:0;
    font-weight: 300;
    text-align: center;
}


@media (max-width: 476px) {
    .map_title {
        margin-top: 30px;
        font-size: 40px;
    }
}