.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(24, 32, 38, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

