.service_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:0 50px;
    height:100%;
}

.service_title {
    color:#47505E;
    margin-top:0;
    font-weight: 300;
}

.service_item{
    position:relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin:0 auto;
    background-size: cover !important;
}

.service_item_title{
    padding: 5px 15px;
    width: 70%;
    background-color: rgba(71, 80, 94,0.7);
    color:white;
    min-height: 60px;
    max-height: 80px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    position:absolute;
    bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 25px;
    font-weight: 400;

}

.service_container .slick-next:before  {
    content: ""
}

.service_container .slick-prev:before {
    content: ""
}

.slick-next{
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
}

.slick-prev{
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
}

@media (max-width: 476px) {
    .service_title {
        margin-top: 30px;
        font-size: 40px;
    }

    .service_item{
        width: 97% !important;
    }

    .text_wrapper {
        width: 75%;
    }

}

@media (min-width: 477px) {
    .service_title {
        margin-top: 0;
        font-size: 60px;
    }

    .text_wrapper {
        width: 75%;
    }

}


